* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --main-color: #65a74f;
  --neutral-main: #676767;
  --maroon-color: #ac345a;
  --main-text-color: #676767;
  --neutral-main-bg: #3a3f4800;
  --neutral-secondary-color: #6c7b88;
  --primary-radius: 0.375rem;
  --edge-color: #dee2e6;
  --main-theme-color: #1f1f1f;
  --main-font: ##676767;
  --red-color: #de4352;
  --yellow-color: #ef9e00;
  --red-color: #df342c;
  --green-color: #1ca54b;
  --secondary-color: #0b122f;
  --brown-color: #0a0d16;
  --font-14px: 14px;
  --purple: #7f56d9;
  --main-bg: #101828;
  --basic-blue: #132146;
  --indicator: #cecece;
  --active-indicator: #226d09;
  --white-color: #ffffff;
  --bs-accordion-btn-icon-width: 1rem !important;
}
p {
  padding: 0;
  margin: 0;
}
body {
  transition: all 0.5s ease-in-out;
}
.CheckboxMenu .dropdown-item:hover,
.CheckboxMenu .dropdown-item:active {
  background-color: transparent;
  color: var(--neutral-main);
}
.info {
  background: #0dcaf0;
}

.views-field-title {
  text-decoration: none;
  font-size: 0.85em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* not needed even though some documentation says it is
  text-overflow: ellipsis; */
}
.d-flx-and-g-1 {
  display: flex;
  gap: 1rem;
}
.cross-icon {
  font-size: 1.7rem !important;
}
.transparent-button {
  padding: 0;
  background: transparent !important;
}

.show-error {
  color: var(--bs-form-invalid-border-color) !important;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
}
.green-color {
  color: var(--green-color) !important ;
}
.red-color {
  color: var(--red-color) !important;
}
.blue-color {
  color: #27367b;
}
.yellow-color {
  color: var(--yellow-color);
}
.disabled {
  background-color: var(--bs-secondary-bg);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
aside,
input,
div,
a {
  color: var(--neutral-main);
  font-family: "Poppins-Regular";
}
.border-none {
  border: 1px solid transparent !important;
}
body {
  font-family: "Poppins-Regular" !important;
}
.customize-switch .form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}
.p-15px {
  padding: 15px;
}
.mb-2px {
  margin-bottom: 2px;
}
label {
  font-weight: 600;
  color: #676767;
}
.dropdown-container {
  z-index: 99 !important;
}
.dropdown-content {
  position: absolute !important;
  z-index: 999999 !important;
}
/* .customize-switch */
.form-check-input:focus {
  border-color: var(--main-color) !important;
  outline: 0;
  box-shadow: none;
  box-shadow: 0 0 0 0.25rem rgb(0 81 4 / 25%);
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e) !important;
}
.font-size-14px {
  font-size: 14px !important;
}
.label-info span {
  color: #676767;
}
.main-color {
  color: var(--main-color);
}
.label-info {
  display: flex;
  align-items: center;
}
.relevant-icon {
  color: var(--main-color);
  font-size: 17px;
  cursor: pointer;
}
.label-info span:last-child {
  font-size: 18px;
  font-family: "Poppins-Regular";
  margin-left: 4px;
  height: 21px;
  color: var(--red-color);
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.general-inp-style label {
  font-size: 13px !important;
  font-weight: 400;
  font-family: "Poppins-Regular" !important;
}
.right-icon-corner input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.general-inp-style.form-floating > .form-control {
  height: 100% !important;
  min-height: 45px !important;
  padding-block: 0 !important ;
}
.general-inp-style.form-floating > label {
  top: -6px;
}
.form-control::placeholder {
  font-size: 12px;
}
.form-check {
  min-height: 40px;
}
.general-inp {
  min-height: 47px;
}

.react-tel-input .special-label {
  background: linear-gradient(180deg, #fff 0%, #fdf0f0 100%) !important;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: none !important;
  border-radius: var(--bs-border-radius);
  background: #fff;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  height: 100%;
  top: 0%;
  border-radius: 0 !important;
}

.react-tel-input > input {
  border-color: transparent !important;
  overflow: hidden;
  border: 0 !important;
}
.react-tel-input .form-control {
  z-index: 9999;
}
.react-tel-input .selected-flag {
  background-color: #f5f5f5 !important;
  position: relative;
  z-index: 9999;
}
.form-control {
  color: var(--bs-body-color);
  outline: 0;
}

.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}
.form-control.is-valid,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: transparent;
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.form-control:valid {
  padding-right: calc(1.5em + 0.75rem);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e"); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid,
.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}

.form-control:focus,
.form-control:hover,
.form-control:active {
  color: var(--bs-body-color);
  border-color: #65a74f;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(101 167 79 / 22%);
}
.form-control:hover.is-invalid,
.was-validated .form-control:hover:invalid {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.css-t3ipsp-control {
  border-color: #65a74f !important;
  box-shadow: 0 0 0 0.25rem rgb(101 167 79 / 22%) !important;
}
.css-tr4s17-option {
  background-color: rgba(10, 20, 6, 0.22) !important;
}
.css-d7l1ni-option {
  background-color: #65a74f !important;
  color: #fff !important;
}
.css-1nmdiq5-menu ::-webkit-scrollbar {
  display: none !important;
}
.css-3w2yfm-ValueContainer {
  flex-wrap: nowrap !important;
}
.css-1nmdiq5-menu {
  position: absolute !important;
  z-index: 999999999 !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.css-1jqq78o-placeholder {
  white-space: nowrap;
  font-size: 12px;
}
.css-t3ipsp-control:hover {
  border-color: #65a74f !important;
}
.css-13cymwt-control {
  border-color: none !important;
  border-style: none !important;
}
.css-t3ipsp-control:hover {
  border-color: none !important;
}
.css-t3ipsp-control {
  border-color: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control {
  border-color: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border-color: none !important;
}
.css-t3ipsp-control:hover {
  border-color: none !important;
}
.css-t3ipsp-control {
  border-color: none !important;
  border-style: none !important;
}
.phone-input-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.phone-input-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.parentCategory {
  margin-top: 1rem;
  color: #676767;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}
.heading-description {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 1rem;
}
.action {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}
.form-label {
  text-wrap: nowrap !important;
  font-weight: 600;
  color: #676767;
}

.upload {
  margin-bottom: 15px;
}
.table-wrapper {
  padding: 25px;
  position: relative;
  z-index: 0;
  transition: 0.3s all ease-in-out;
}
.table-wrapper > section {
  height: 100%;
}
.action-wrapper {
  padding: 25px;
  z-index: 2;
  position: relative;
}
.page-indicator {
  display: flex;
  flex-direction: column;

  margin-bottom: 5px;
}
.table-operations {
  display: flex;
  justify-content: space-between;
}
.pagination-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.85rem;
}
.pagination-wrapper p {
  margin: 2px;
}
.adjust-limit-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.adjust-limit-container p {
  margin: 0;
}
.adjust-padding {
  padding: 20px 15px;
}

.searchbar-styling {
  background: #fff;
  border-radius: 0.375rem;
  height: 38px;
  border: 1px solid #dee2e6;
  overflow-y: hidden;
}
.searchbar-container {
  padding: 10px 15px;
}
.searchbar-styling > div > div input {
  background: white;
  border-right: 1px solid #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  padding: 0.375rem 0.75rem;
}
.action-component-section {
  padding: 15px;
}
.no-border {
  border: none !important;
}

.react-tel-input .country-list .flag {
  margin-top: 4px !important;
}
.react-tel-input .country-list .country-name {
  margin-left: 28px;
}
.react-tel-input {
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
}
.custom-phone-class {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
}
.custom-phone-class input:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.custom-phone-class input:invalid {
  border-color: transparent !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.custom-phone-class input:valid {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.custom-phone-class input:valid:focus {
  border-color: transparent;
  border: var(--bs-border-width) solid var(--bs-border-color);
  box-shadow: none;
}
.custom-phone-class input:focus {
  border-color: transparent !important;
  /* border: var(--bs-border-width) solid var(--bs-border-color) !important; */
  box-shadow: none;
}
.valid-phonenumber input {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.valid-phonenumber input:focus {
  box-shadow: 0 0 0 0.25rem rgb(101 167 79 / 22%) !important;
}
.invalid-phonenumber input {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.valid-phonenumber:focus {
  box-shadow: 0 0 0 0.25rem rgb(101 167 79 / 22%) !important;
}
.invalid-phonenumber:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}
.invalid-phonenumber {
  border-color: var(--bs-form-invalid-border-color) !important;
  border: 1px solid var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.invalid-phonenumber input {
  border-color: transparent !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* ========== react date time picker ========== */
.react-datetime-picker__wrapper {
  display: flex;
  flex-shrink: 0 !important;
}
@media (max-width: 375px) {
  .react-datetime-picker__wrapper {
    display: flex;
    flex-shrink: 0 !important;
  }
}

/*
=-===================== 
Cutomize date time picker
*/

.react-datetime-picker__wrapper {
  border: none !important;
}
.react-datetime-picker__inputGroup__input:focus-visible {
  outline: 1px solid var(--main-color);
}
.react-datetime-picker__inputGroup__input::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: var(--main-color);
}
.react-datetime-picker__inputGroup__input::selection {
  background: var(--main-color);
  color: #fff;
}

.react-datetime-picker__calendar {
  z-index: 9999;
}
.react-datetime-picker__calendar-button:hover {
  color: red !important;
}
.react-datetime-picker__calendar-button:hover svg {
  color: var(--main-color) !important;
}
.react-datetime-picker__calendar-button svg,
.react-datetime-picker__clear-button svg {
  height: 16px;
  width: 16px;
}
.react-datetime-picker__button:enabled:hover
  .react-datetime-picker__button__icon {
  stroke: var(--main-color);
}
.react-calendar__tile--active {
  background: var(--main-color);
}
.react-calendar__tile--active:hover {
  background-color: #97b68d !important;
}
.react-datetime-picker__inputGroup__input option:checked {
  background: var(--main-color) !important;
  color: #fff !important;
}
.react-datetime-picker__inputGroup__input option:hover {
  background: var(--main-color) !important;
  color: #fff !important;
}
.react-datetime-picker__inputGroup input,
.react-datetime-picker__inputGroup select {
  font-size: 12px;
}
.cancel-button {
  background: rgb(233, 235, 240) !important;
  color: #828181 !important;
  transition: all 0.3s ease-in-out !important;
}
.cancel-button:hover {
  background: #de4352 !important;
  color: #fff !important;
}
.ant-result {
  margin-top: 100px;
}
.ant-btn {
  background-color: var(--main-color);
}
.ant-btn > span {
  color: white !important;
}
.ant-btn:hover {
  color: white !important;
  background-color: var(--main-color) !important;
}
.ant-result-icon > svg > g > path:nth-child(11) {
  fill: var(--main-color) !important;
}
.ant-result-icon > svg > g > g > path {
  fill: var(--main-color) !important;
  opacity: 0.3;
}
/* customize toast color */
.Toastify__progress-bar--success {
  background: var(
    --main-color
  ) !important; /* Replace with your desired color */
  color: white;
}
.Toastify__toast--success svg {
  fill: var(--main-color) !important;
}
.Toastify__zoom-enter > div {
  color: var(--main-font) !important;
}
/*Password Input*/
.password-input-container {
  position: relative;
}
.password-input {
  position: relative;
}
.password-input.form-control:invalid,
.password-input.form-control:valid {
  background-image: none !important;
}
.icon-color {
  color: var(--main-color);
  font-size: 1.5rem;
  cursor: pointer;
}
.disabled-input .form-control {
  background-color: var(--bs-secondary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}
.infoAndAction-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Toastify {
  position: relative !important;
  z-index: 999999 !important;
}
.overflowStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-container {
  position: absolute;
  right: 3%;
  bottom: 10%;
}
.antd-result-subtitle {
  font-size: 20px !important;
}
.css-qbdosj-Input {
  color: var(--neutral-main) !important;
}
.only-date-picker .react-date-picker__wrapper {
  border: none;
}
.only-date-picker-wrapper .react-date-picker__calendar-button svg {
  fill: red !important;
}
.react-date-picker__calendar {
  z-index: 999999999;
}
/*check all group*/
.check-all-group label {
  text-align: left;
  color: var(--neutral-main);
}
/*Checkbox stylng*/
.form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
.check-all-group input {
  padding: 7.5px;
}

.dashboard-stats {
  padding: 15px;
}
.card-wrapper {
  overflow-x: auto;
}
.card-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.card-wrapper::-webkit-scrollbar-track {
  background: #f0f9ed !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.card-wrapper::-webkit-scrollbar-thumb {
  background: var(--main-color) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.card-wrapper > div {
  width: 100%;
  min-width: 1300px;
}
.card {
  border-radius: 0;
}
/*
==================
dashboard
==================
*/
.tabs .nav-link.active:hover {
  color: var(--main-color);
  border-color: var(--bs-nav-tabs-link-active-border-color) !important;
}
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  color: var(--main-color);
}
.tabs .nav-link:hover {
  border-color: transparent;
}
.nav-tabs .nav-link {
  color: var(--main-text-color);
  font-weight: 500 !important;
}
.phone-input-container .phone-input .country-list {
  width: 100% !important;
}
.flag-dropdown {
  width: 100%;
}
.country-list {
  width: 100% !important;
}
.country-list::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.country-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/*
============================
animation on filtering block
=============================
*/
.filter-block {
  max-height: 0;
  transform: translateY(-10px);
  opacity: 0;
  transition: all ease-out 0.8s;
  transition-timing-function: ease;
}
.show-filter {
  max-height: 1000px;
  opacity: 1;
  transition: all ease-in-out 1.3s;
  transition-timing-function: ease;
  z-index: 99999999;
  position: relative;
}
.filter-action {
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 80px !important;
}
.filter-action img {
  width: 18px;
}
.top-action-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modal {
  z-index: 999999999999 !important;
}
.modal-backdrop.show {
  z-index: 9999999999 !important;
}
@media (max-width: 576px) {
  .nav-tabs .nav-link {
    font-size: 0.8rem;
    padding: 10px;
  }
}

@media (max-width: 760px) {
  .textField {
    margin-bottom: 15px;
  }
  .form-label {
    margin-bottom: 3px;
  }
}
@media (max-width: 500px) {
  .searchbar-styling {
    width: 100%;
  }
  .top-action-container {
    flex-direction: column;
  }
  .pagination-container {
    margin-top: 1rem;
    flex-direction: column;
  }
}
@media (max-width: 392px) {
  .top-action-container {
    flex-direction: row;
  }
  .infoAndAction-wrapper button {
    width: 100%;
  }
  .infoAndAction-wrapper {
    flex-direction: column;
    align-items: start;
    margin-bottom: 1.2rem;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .table-wrapper {
    padding: 15px !important;
  }
  .action-wrapper {
    padding: 15px;
  }
  .table-operations {
    flex-direction: column;
  }
  .search-container > div {
    width: 100%;
  }
  .page-indicator {
    margin-bottom: 0 !important;
    justify-content: center;
  }
  .customize-switch {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .relevant-icon {
    color: var(--main-color);
    font-size: 12px;
    cursor: pointer;
  }
}

.was-validated
  select:invalid
  + .select.select-container.select-container--default
  span.select2-selection,
select.select2.is-invalid
  + .select2.select2-container.select2-container--default
  span.select2-selection {
  border-color: #fa5c7c;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated
  select:invalid
  + .select2.select2-container.select2-container--default
  .select2-selection__arrow,
select.select2.is-invalid
  + .select2.select2-container.select2-container--default
  .select2-selection__arrow {
  right: 25px !important;
}

.was-validated
  select:valid
  + .select2.select2-container.select2-container--default
  span.select2-selection,
select.select2.is-valid
  + .select2.select2-container.select2-container--default
  span.select2-selection {
  border-color: #0acf97;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated
  select:valid
  + .select2.select2-container.select2-container--default
  .select2-selection__arrow,
select.select2.is-valid
  + .select2.select2-container.select2-container--default
  .select2-selection__arrow {
  right: 25px !important;
}

.RequestItem_negoWrapper__QZOjP::-webkit-scrollbar-track {
  background: #f0f9ed !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.RequestItem_negoWrapper__QZOjP::-webkit-scrollbar-thumb {
  background: var(--main-color) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
