.main-navbar .navbar-toggler-icon {
  background-image: url("../assets/images/menu_dots.webp") !important;
  width: 22px;
  height: 22px;
}
.main-navbar button {
  border-color: none;
  box-shadow: none;
  outline: none;
  border: none;
}
.main-navbar button:focus,
.main-navbar button:active,
.main-navbar button:hover {
  border-color: none;
  box-shadow: none;
  outline: none;
  border: none;
}
