/*In root we defined variables to adjust the height and width of spinerz */
/*You can also adjust color but most os=f the time color should be main color*/
/* :root {
  --spiner-width: 2rem;
  --spiner-height: 2rem;
}
.loader-wrapper {
  opacity: 1 !important;
  z-index: 99 !important;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 50%;
}
.loader-wrapper__container div {
  margin-inline-end: 1rem;
  inline-size: var(--spiner-width);
  block-size: var(--spiner-height);
  color: var(--main-color);
} */
body {
  position: relative;
}

.loader-wrapper {
  /* z-index: 9999999999 !important;
  position: fixed;
  top: 40%;
  left: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-align-items: center; */

  z-index: 9999999999999 !important;
  position: fixed;
  top: 0px;
  background: #fff;
  /* opacity: 0.5; */
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.loader-box {
  height: 150px;
  width: 252px;
  display: flex;
  position: relative;
  width: 250px;
  justify-content: center;
  align-items: center;
  -ms-align-items: center;
  border-radius: 20px;
  overflow: hidden;
}
.loader-box > img {
  /* height: 60px; */
  width: 142px;
  z-index: 2;
}

.loader-box::after {
  content: "";
  position: absolute;
  background-color: whitesmoke;
  inset: 5px;
  border-radius: 16px;
}
.loader-box::before {
  content: "";
  position: absolute;
  height: 200%;
  width: 50%;
  background: linear-gradient(#65a74f, #ac3259);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
