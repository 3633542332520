/* :root {
  --blue: #0e0620;
  --white: #fff;
  --green: #2ccf6d;
} */
.main {
  height: 100%;
  width: 70%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.div-404 > h1 {
  font-size: 130px;
}

.div-text {
  /* padding-left: 100px; */
}

.btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.5s ease;
  font-weight: bold;
  margin: 8px 0px;
  &.green {
    border: 4px solid var(--main-color);
    color: var(--main-color);
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: var(--main-color);
      z-index: -1;
      transition: 0.5s ease;
    }
    &:hover {
      color: var(--white-color);
      background: var(--main-color);
      transition: 0.5s ease;
      &:before {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main {
    margin-top: 30%;
    height: 40%;
    flex-direction: column-reverse;
  }
  .div-text {
    text-align: center;
  }
}
