table {
  border-collapse: collapse !important;
  /* border-collapse: separate !important; */
  /* border-spacing: 0 8px !important; */
  padding: 0 12px;
  width: 100%;
  /* border: 4px solid red !important; */
  position: relative;
  border-collapse: initial !important; /* or collapse */
  border-spacing: 0px;
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}
html {
  scrollbar-width: none;
}
tbody {
  overflow-x: auto;
  width: 100%;
  /* height: 200px; */
}
thead {
  position: sticky;
  top: 0 !important;
  z-index: 9999999;
}

tr {
  border-style: unset;
  border-radius: 12px !important;
  border: 1px solid #dee2e6;
}

th {
  background-color: #f0f9ed !important;
  padding: 9px 8px !important;
  text-align: initial;
  color: var(--neutral-main) !important;
  border-top: 0px solid #dee2e6 !important;
  font-weight: normal !important;
  font-size: 12px;
  font-family: sans-serif;
  text-transform: uppercase;
}
td {
  padding: 8px !important;
  /* text-align: center; */
  color: #676767 !important;
  vertical-align: middle;
}
th:last-child,
td:last-child {
  position: -webkit-sticky;
  z-index: 9999;
  position: sticky !important;
  right: 0 !important;
}
th:last-child {
  position: fixed !important;
  top: 0;
}
.header {
  margin-bottom: 20px;
}
.pageMain {
  height: calc(100vh - 120px);
  scrollbar-color: track-color thumb-color;
  scrollbar-width: thin;
  overflow: auto;
}
.pageMain::-webkit-scrollbar {
}
.table-data-records {
  overflow-y: scroll;
}

.table-data-records::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  /* Hide scrollbar for WebKit/Blink */
  /* Chrome, Safari, Opera */
}

/* Hide vertical scrollbar for Firefox */
/* Track */
.table-data-records::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.table-data-records::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

table {
  position: relative;
  border-collapse: initial !important; /* or collapse */
  border-spacing: 0px;
}
th:last-child,
td:last-child {
  position: -webkit-sticky;
  position: sticky !important;
  right: 0% !important;
  z-index: 9999;
}
thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}

/* For Firefox */
/* For Firefox */

@supports (-moz-appearance: none) {
  /* For Firefox */
  .table-data-records {
    scrollbar-width: thin;
    scrollbar-color: var(--main-color) #fff;
  }

  /* Track */
  .table-data-records {
    scrollbar-track-color: #fff;
  }

  /* Handle */
  .table-data-records {
    scrollbar-thumb-color: var(--main-color);
  }
}

/* For WebKit-based browsers (Chrome, Safari, etc.) */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Hide vertical scrollbar */
  .table-data-records {
    overflow-y: hidden;
  }
}
